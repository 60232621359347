import __C from '../../../../src/primitives/_constant_'
import { fetchURL, removePrefix } from './utils'

export async function fetch (url, name='') {
  url = removePrefix(url)

  const res = await fetchURL(`/resources?path=${url}&name=${name}`, {})
  if (res.status === 200) {
    let data = await res.json()
    // data.url = `/files${url}`

    if (data.isDir) {
      data.items = data.items.map((item, index) => {
        item.index = index
        item.url = `${url}${url.length > 1 ? '/' : ''}${encodeURIComponent(item.name)}`
        return item
      })
    }
    return data
  } else {
    throw new Error(res.status)
  }
}

async function resourceAction (url, method, content) {
  url = removePrefix(url)

  let opts = { method }

  if (content) {
    opts.body = content
  }

  const res = await fetchURL(`/resources?path=${url}`, opts)

  if (res.status !== 200) {
    throw new Error(res.responseText)
  } else {
    return res
  }
}

export async function remove (url) {
  return resourceAction(url, 'DELETE')
}

export async function put (url, content = '') {
  return resourceAction(url, 'PUT', content)
}

export function download (format, name, ...files) {
  let url = `${__C.HOST_NAME_API}/v0/filebrowser/raw`
  
  if (files.length === 1) {
    url += `?path=${files[0]}&name=${name}&`

  } else {
    let arg = ''

    for (let file of files) {
      arg += removePrefix(file) + ','
    }

    arg = arg.substring(0, arg.length - 1)
    arg = encodeURIComponent(arg)
    url += `/?name=${name}&files=${arg}&`
  }

  if (format !== null) {
    url += `algo=${format}&`
  }
  
  var iframe = document.createElement('iframe')
  document.body.appendChild(iframe)
  iframe.src = url
}

export async function post (url, name, content = '', overwrite = false, onupload) {
  url = removePrefix(url)

  return new Promise((resolve, reject) => {
    let request = new XMLHttpRequest()
    request.open('POST', `${__C.HOST_NAME_API}/v0/filebrowser/resources?path=${url}&name=${name}&override=${overwrite}`, true)

    if (typeof onupload === 'function') {
      request.upload.onprogress = onupload
    }

    // Send a message to user before closing the tab during file upload
    window.onbeforeunload = () => "Files are being uploaded."

    request.onload = () => {
      if (request.status === 200) {
        resolve(request.responseText)
      } else if (request.status === 409) {
        reject(request.status)
      } else {
        reject(request.responseText)
      }
    }

    request.onerror = (error) => {
      reject(error)
    }

    request.send(content)
    // Upload is done no more message before closing the tab 
  }).finally(() => { window.onbeforeunload = null })
}

function moveCopy (name, items, copy = false) {
  let promises = []

  for (let item of items) {
    const from = removePrefix(item.from)
    const to = encodeURIComponent(removePrefix(item.to))
    const url = `${from}&name=${name}&action=${copy ? 'copy' : 'rename'}&destination=${to}`
    promises.push(resourceAction(url, 'PATCH'))
  }

  return Promise.all(promises)
}

export function move (name, items) {
  return moveCopy(name, items)
}

export function copy (name, items) {
  return moveCopy(name, items, true)
}

export async function checksum (url, algo) {
  const data = await resourceAction(`${url}?checksum=${algo}`, 'GET')
  return (await data.json()).checksums[algo]
}
